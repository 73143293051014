import logo from "@/assets/images/common/logo-cric-login.png";

export const centeredText = (text, y, doc) => {
    const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
};

export const buildDefaultHeader = (doc) => {
    const headerLines = ["Consejo Regional Indígena del Cauca CRIC - Nacional",
        "Autoridad Tradicional",
        "Resolución No. 025 de 8 de junio de 1999 Dirección General de",
        "Asuntos Indígenas del Ministerio del Interior",
        "NIT. 817.002.466-1"
    ];
    doc.addImage(logo, 'PNG', 10, 7, 30, 25);
    const lineHeight = 4;
    let startPos = 10;
    headerLines.forEach((line) => {
        startPos += lineHeight;
        centeredText(line, startPos, doc);
    });
}
export const buildDefaultFooter = (doc) => {
    const footerLines = ["CALLE 1ª No. 4-50 CONMUTADOR: 8242153 –FAX (2) 8234293-A. A516",
        "E-mail:cric@cric-colombia.org – www.cric-colombia.org - defensavida_cric@outlook.com",
        "POPAYAN – CAUCA - COLOMBIA"
    ];
    const lineHeight = 5;
    let startPos = 270;
    footerLines.forEach((line) => {
        startPos += lineHeight;
        centeredText(line, startPos, doc);
    });
}

export const multicell = (doc, text, x, y, w) => {
    const lines = doc.splitTextToSize(text, w);
    doc.text(x, y, lines);
}
