<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="overflow-hidden">
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="font-weight-bold" color="grey darken-2">
                            BUSCAR COMUNERO
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="mx-0">
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field
                                        v-model="document_number"
                                        label="Documento"
                                        prepend-icon="mdi-card-account-details"
                                        single
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    class="d-flex flex-column justify-end"
                                    cols="4"
                                    lg="4"
                                    md="12"
                                    sm="12"
                            >
                                <div>
                                    <v-btn
                                            class="mb-5"
                                            color="primary"
                                            dark
                                            rounded
                                            @click="searchComuner()"
                                    >
                                        BUSCAR COMUNERO
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="overflow-hidden mt-2">
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="font-weight-bold" color="grey darken-2">
                            RESULTADOS DE BUSQUEDA DOCUMENTO : {{ document_number }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="mx-0">
                            <v-row class="mt-2">
                                <v-chip small color="red" text-color="white" class="ma-2" v-if="isDeath">
                                    <v-icon small class="mr-1">mdi-alert-circle</v-icon>
                                    Novedad de fallecimiento
                                </v-chip>
                                <v-chip small color="orange darken-2" class="ma-2" text-color="white" v-if="!isActive && Object.keys(results).length">
                                    <v-icon small class="mr-1">mdi-account-off</v-icon>
                                    Comunero Inactivo
                                </v-chip>
                                <v-chip small color="green darken-2" class="ma-2" text-color="white" v-if="isActive && Object.keys(results).length && !isDeath">
                                    <v-icon small class="mr-1">mdi-account-check</v-icon>
                                    Comunero Activo
                                </v-chip>
                            </v-row>
                            <v-row>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Fecha de Registro</span>
                                    <h5>{{ results.fecharegistro }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Tipo de documento</span>
                                    <h5>{{ results.tipo_de_identificacion }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Número de identificación</span>
                                    <h5>{{ results.numeroidentificacion }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Primer Nombre</span>
                                    <h5>{{ results.nombre1 }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Segundo Nombre</span>
                                    <h5>{{ results.nombre2 }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Primer Apellido</span>
                                    <h5>{{ results.apellido1 }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Segundo Apellido</span>
                                    <h5>{{ results.apellido2 }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Fecha de Nacimiento</span>
                                    <h5>{{ results.fechanacimiento | formatDate }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Reside en territorio</span>
                                    <h5>{{ results.vive_en_resguardo }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Género</span>
                                    <h5>{{ getGender(results.sexo) }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Identidad poblacional</span>
                                    <h5>{{ results.etnia_desc }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Pueblo</span>
                                    <h5>{{ results.pueblo_desc }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Nacionalidad</span>
                                    <h5>{{ results.nacionalidad_desc }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Territorio Actual</span>
                                    <h5>{{ (territory?.descripcion) || '' }}</h5>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <span class="green--text">Municipio</span>
                                    <h5>{{ municipality?.descripcion || '' }}</h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_nucleodelcomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Parentesco nucleo Familiar</span>
                                    <h5 v-if="results.comunero_nucleodelcomunero.length">
                                        {{ getLastItem(results.comunero_nucleodelcomunero).descripcion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_estadocivilcomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Estado Civil</span>
                                    <h5 v-if="results.comunero_estadocivilcomunero.length">
                                        {{ getLastItem(results.comunero_estadocivilcomunero).descripcion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_ocupacioncomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Ocupación principal</span>
                                    <h5 v-if="results.comunero_ocupacioncomunero.length">
                                        {{
                                        getLastItem(results.comunero_ocupacioncomunero.filter(x => x.ocupacion_principal === 'S'))?.descripcion
                                        }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_ocupacioncomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Ocupación secundaria</span>
                                    <h5 v-if="results.comunero_ocupacioncomunero.length">
                                        {{
                                        getLastItem(results.comunero_ocupacioncomunero.filter(x => x.ocupacion_principal === 'N'))?.descripcion
                                        }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_saberescomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Saberes Ancestrales</span>
                                    <h5 v-if="results.comunero_saberescomunero.length">
                                        {{ getLastItem(results.comunero_saberescomunero)?.descripcion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_estudiocomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Nivel de Estudio</span>
                                    <h5 v-if="results.comunero_estudiocomunero.length">
                                        {{ getLastItem(results.comunero_estudiocomunero)?.descripcion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_estudiocomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Institución</span>
                                    <h5 v-if="results.comunero_estudiocomunero.length">
                                        {{ getLastItem(results.comunero_estudiocomunero)?.institucion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_ausenciacomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Motivo de Ausencia</span>
                                    <h5 v-if="results.comunero_ausenciacomunero.length">
                                        {{ getLastItem(results.comunero_ausenciacomunero)?.descripcion }}
                                    </h5>
                                </v-col>
                                <v-col
                                        v-if="results['comunero_espiritualidadcomunero']"
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                >
                                    <span class="green--text">Practicas religiosas o culturales espirituales</span>
                                    <h5 v-if="results.comunero_espiritualidadcomunero.length">
                                        {{ getLastItem(results.comunero_espiritualidadcomunero)?.descripcion }}
                                    </h5>
                                </v-col>
                            </v-row>
                            <v-row v-if="Object.keys(results).length && !disableCertificate" class="mb-4 mt-14"
                                   justify="center">
                                <v-col cols="12" lg="3" md="3" sm="12">
                                    <v-btn
                                            class="primary"
                                            rounded
                                            @click="downloadCertificate()"
                                    >
                                        DESCARGAR CERTIFICADO DE CENSO
                                        <v-icon class="ml-2" left>mdi-file-download</v-icon>
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-row>
                    </v-card-text>
                    <v-snackbar v-model="alertState" color="error">
                        <v-icon>mdi-alert</v-icon>
                        El comunero se encuentra inactivo en censo.
                    </v-snackbar>
                    <!-- snackbar danger with icon with model alertDeath -->
                    <v-snackbar v-model="alertDeath" color="error" icon="mdi-alert">
                        <v-icon>mdi-alert</v-icon>
                        El comunero presenta novedad por fallecimiento.
                    </v-snackbar>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {getCommonerUsingValidation, getHousingInfoByCommoner} from "@/services/censusService";
import sweetalert from "sweetalert2";
import generateCertificate from "@/utils/certificateCensus";
import axios from "axios";

const ESTADO_ACTIVO_ID = 2;
export default {
    name: "Reports.vue",
    data() {
        return {
            document_number: "",
            disableCertificate: false,
            alertState: false,
            isActive : true,
            alertDeath: false,
            isDeath : false,
            results: {},
            housingInfo: {},
            currentTerritory: {},
            currentMunicipality: {},
            currentDepartment: {},
        };
    },
    methods: {
        async searchComuner() {
            try{
                this.disableCertificate = false;
                this.alertState = false;
                this.alertDeath = false;
                this.results = {};
                this.housingInfo = {};
                this.isActive = true;
                this.isDeath = false;
                this.showLoader();
                const response = await getCommonerUsingValidation(this.document_number);
                if(!response.data.results.length) {
                    this.hideLoader();
                    sweetalert.fire("Error", "No se encontraron resultados", "warning");
                    return;
                }

                const {data} = await getHousingInfoByCommoner(this.document_number);
                if (data.data.length) {
                    this.housingInfo = data.data[0];
                }

                this.results = response.data.results[0];

                if (this.getLastItem(this.results.comunero_estadocomunero)?.estado !== ESTADO_ACTIVO_ID) {
                    this.disableCertificate = true;
                    this.alertState = true;
                    this.isActive = false;
                }
                if (this.results.comunero_fallecimiento.length) {
                    this.disableCertificate = true;
                    this.alertDeath = true;
                    this.isDeath = true;
                }

                this.hideLoader();
            }catch (e) {
                console.error(e);
                const axiosErrorHandler = (status) => {
                    switch(status){
                        case 404:
                            sweetalert.fire("Error", "No se encontraron resultados", "warning");
                            break;
                        case 403:
                            sweetalert.fire("Error", "No cuenta con los permisos para ver la información", "warning");
                            break;
                        default:
                            sweetalert.fire("Error", "Ocurrio un error al procesar la solicitud", "error");
                            break;
                    }
                }

                e instanceof axios.AxiosError && axiosErrorHandler(e.response.status);
                !(e instanceof axios.AxiosError) && sweetalert.fire("Error", "Ocurrio un error al procesar la solicitud", "error");
                this.hideLoader();

            }
        },
        getLastItem(array) {
            return array[array.length - 1];
        },
        downloadCertificate() {
            generateCertificate(this.results, {
                sideWalk: this.sideWalk,
                territory: this.territory,
                municipality: this.municipality,
                department: this.department,
            });
        },
        getGender(gender) {
            return {
                "1": "Femenino",
                "2": "Masculino",
                "3": "Ninguno"
            }[String(gender)]
        },
    },
    computed : {
        sideWalk() {
            if(this.housingInfo){
               return  this.$store.state.dataSource.data.Vereda.find(item => item.vereda_id === this.housingInfo.vereda);
            }
            return {};
        },
        territory() {
            if(this.sideWalk){
                return this.$store.state.dataSource.data.Territorio.find(item => item.territorio_id === this.sideWalk.territorio);
            }
            return {};
        },
        municipality() {
            if (this.territory) {
                return this.$store.state.dataSource.data.Municipio
                    .find(item => {
                        return Boolean(item.municipio_territoriomunicipio.find(
                            territory => territory.territorio_id === this.territory.territorio_id
                        ))
                    });
            }
            return {};
        },
        department(){
            if(this.municipality){
                return this.$store.state.dataSource.data.Departamento
                    .find(item => item.departamento_id === this.municipality.departamento);
            }

            return {};
        }
    }

};
</script>

<style scoped></style>
