import downloadFile from "../utils/downloadFile";
import baseService from "./baseService";

const resource = "/rest/v1/census-mngr";

export function infoCards() {
  return baseService.get(`${resource}/cards`);
}

export async function reports(filters = {}) {
  const response = await baseService.post(`${resource}/reports`, filters, {
    responseType: "blob",
  });

  downloadFile(response.data, "application/vnd.ms-excel", "Reporte - Censo Indígena.xlsx");
}

export function searchComuner(document_number) {
  return baseService.get(`${resource}/get-commoner/${document_number}`);
}

export function getHousingInfoByCommoner(document_number) {
  return baseService.get(`${resource}/get-housing-by-commoner/${document_number}`);
}

export function getCommonerUsingValidation(documentNumber){
  return baseService.get(`${resource}/get-commoner-using-validation/${documentNumber}`);
}
