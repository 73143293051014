import { jsPDF } from "jspdf";
import logo from '../assets/images/common/logo-cric-login.png';
import {centeredText} from "@/utils/utilsPDF";

const buildHeader = (doc) => {
    const headerLines = ["Consejo Regional Indígena del Cauca CRIC - Nacional",
    "Autoridad Tradicional",
    "Resolución No. 025 de 8 de junio de 1999 Dirección General de",
    "Asuntos Indígenas del Ministerio del Interior",
    "NIT. 817.002.466-1"
   ];
    doc.addImage(logo, 'PNG', 10, 7, 30, 30);
    const lineHeight = 4;
    let startPos = 10;
    headerLines.forEach((line) => {
        startPos += lineHeight;
        centeredText(line, startPos, doc);
    });
}
const buildFooter = (doc) => {
    const footerLines = ["CALLE 1ª No. 4-50 CONMUTADOR: 8242153 –FAX (2) 8234293-A. A516",
    "E-mail:cric@cric-colombia.org – www.cric-colombia.org - defensavida_cric@outlook.com",
    "POPAYAN – CAUCA - COLOMBIA"
   ];
    const lineHeight = 5;
    let startPos = 270;
    footerLines.forEach((line) => {
        startPos += lineHeight;
        centeredText(line, startPos, doc);
    });
}
export default function generateCertificate(census, territoryInfo = {}) {
    const doc = new jsPDF();
    doc.setFont("Helvetica");
    doc.setLineHeightFactor(1.5);
    doc.setFontSize(11);
    buildHeader(doc);

    doc.setFontSize(11).setFont("Helvetica", "bold");
    doc.text("El Consejo Regional del Cauca CRIC", 20, 70)

    centeredText("HACE CONSTAR", 90, doc);

    doc.setFontSize(11).setFont("Helvetica", "normal");

    const currentYear = new Date().getFullYear();
    const text = `Que consultado el censo sistematizado, se registra el/la Señor (a): ${census.nombre1}${census.nombre2 ? ' ' +census.nombre2 : ' '}`+
        ` ${census.apellido1}${census.apellido2 ? ' ' +census.apellido2 : ' '} ` +
        `identificado con ${census.tipo_de_identificacion} y número de documento: ${census.numeroidentificacion}, con ubicación en el territorio de ` +
        `${territoryInfo.territory?.descripcion} en la comunidad o vereda ${territoryInfo.sideWalk?.descripcion} en el censo del año ${currentYear}.`;
    const splitText = doc.splitTextToSize(text, 180);
    doc.text(18, 100, splitText)

    doc.setFontSize(11).setFont("Helvetica", "normal");

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateText = `Se expide a los ${day} día(s) del mes ${month} del año ${year}.`;
    doc.text(dateText, 20, 150);

    const textComplementary = "La presente información se emite conforme a los registros que al día de hoy reposan en el Sistema de Censo del Consejo Regional del Cauca CRIC";
    const splitTextComplementary = doc.splitTextToSize(textComplementary, 180);
    doc.text(20, 160, splitTextComplementary);

    doc.setFontSize(11).setFont("Helvetica", "normal");
    doc.line(20, 200, 100, 200);
    doc.text("FIRMA AUTORIDAD", 20, 210);
    buildFooter(doc);
    doc.save(`certificado-censo-${census.numeroidentificacion}.pdf`);
}
